// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

ActiveStorage.start()

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'

// Nested forms
import "@oddcamp/cocoon-vanilla-js"

// Charts
import "chartkick/chart.js"
